import React from "react"
import window from "global"
import {Button, Col, Container, Row} from "react-bootstrap"
import {graphql, Link, useStaticQuery} from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import Carousel from 'react-bootstrap/Carousel'
import BackgroundImage from "gatsby-background-image"
import Fade from 'react-reveal/Fade';

function Index() {
    const data = useStaticQuery(graphql`
        query homeQuery {
            backgroundImage: file(
                relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/HOME_achtergrond.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            blueBackground: file(
                relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/Figuur_Blauw.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            helm: file(
                relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/Helm_Home.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            clock: file(
                relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/Icon_klok.png" }
            ) {
                childImageSharp {
                    fixed(width: 70) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            backgrounds: allFile(
                filter: {ext: {} relativeDirectory: { eq: "HOME/LOSSE AFBEELDINGEN/slides" }}) {
                nodes {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
            iconPlan: file(relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/icons/Icon_Helder.png" }) {
                childImageSharp {
                    fixed(width: 200) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            iconPaper: file(relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/icons/Icon_Quiz.png" }) {
                childImageSharp {
                    fixed(width: 200) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            iconDiamond: file(relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/icons/Icon_Diamond.png" }) {
                childImageSharp {
                    fixed(width: 200) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            iconCustomer: file(relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/Klant.png" }) {
                childImageSharp {
                    fixed(width: 200) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            tools: file(relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/Achtergrond_materiaal.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            allGoogleReview{
                edges{
                    node{
                        name
                        review
                        rating
                        id
                        url
                        image
                    }
                }
            }
        }
    `)

    const [hasMounted, setHasMounted] = React.useState(false)
    const [width, setWidth] = React.useState(window.innerWidth)
    const updateWidth = () => {
        setWidth(window.innerWidth)
    }

    React.useEffect(() => {
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
    }, [])

    React.useEffect(() => {
        setHasMounted(true)
    }, [width])

    if (!hasMounted) {
        return null
    }
    let coverText = (
        <Col
            className={"head-text text-center"}
            style={{color: "white", marginTop: "30vh"}}
            md={{span: 8, offset: 2}}>
            <Fade top duration="2000">
                <h1 style={{fontSize: "4em"}}>DE VASTGOEDSPECIALIST</h1>
            </Fade>
            <Fade bottom delay="500" duration="1500">
                <h2 style={{fontSize: "2.5em"}}>
                    Alders Vastgoedonderhoud,<br></br> alles onder één dak.
                </h2>
            </Fade>
        </Col>
    )
    let reviewCarouselStyling = {transform: "scale(0.75)", height: "500px"}
    if (width <= 1250) {
        reviewCarouselStyling = {transform: "scale(0.75)", height: "700px"}
        coverText = (
            <Col
                className={"head-text text-center"}
                style={{color: "white", marginTop: "-60vh", zIndex: -1}}
                md={{span: 8, offset: 2}}>
                <Fade top duration="2000"><h1 style={{fontSize: "7vw"}}>DE VASTGOED-SPECIALIST</h1></Fade>
                <Fade bottom delay="500" duration="1500">
                    <h2 style={{fontSize: "5vw"}}>
                        Alders Vastgoedonderhoud,<br></br> alles onder één dak.
                    </h2>
                </Fade>
            </Col>
        )
    }

    let helmSection = (
        <Container fluid className="my-5" style={{color: "#182F55", maxWidth: "700px"}}>
            <Row style={{textAlign: "center"}}>
                <Col>
                    <Fade left>
                        <h2 className="head-text mb-5">ALDERS VASTGOED&shy;ONDERHOUD IS DOOR HET HELE LAND ACTIEF, 24/7</h2>
                    </Fade>
                    <Fade right>
                        <p className="normal-text">Het totaalonderhoud van uw woning is voor ons het belangrijkste. Wij streven naar een goede
                            samenwerking, waarbij u van begin tot eind kunt bouwen op ons vakmanschap. Wij voeren ons werk zorgvuldig uit, om de
                            kwaliteit te bewaken.<br></br><br></br>
                        </p>
                    </Fade>
                    <Fade left>
                        <p className="normal-text">Alders Vastgoedonderhoud is door heel Nederland actief. Daarnaast staan onze vakspecialisten 24/7
                            voor u klaar. Bij noodgevallen kunt u direct met ons contact opnemen, zodat het probleem zo snel mogelijk wordt
                            opgelost.</p>
                    </Fade>
                    <Fade right>
                        <a href="tel:+31-629543404" rel="nofollow" className={"head-text no-underline mt-5 py-2 btn spoedService"} style={{
                            minWidth: "300px",
                            backgroundColor: "#182F55",
                            borderRadius: 0,
                            display: "inline-block",
                            border: 0,
                            color: "white"
                        }}><Image style={{position: "absolute", zIndex: 1, bottom: "-12px", left: "-10%"}}
                                  fixed={data.clock.childImageSharp.fixed}/><span className="showText">SPOEDSERVICE</span><span
                            className="hiddenText">06 29 54 34 04</span></a>
                    </Fade>
                </Col>
            </Row>
        </Container>
    )
    if (width >= 1250) {
        helmSection = (
            <BackgroundImage fluid={data.blueBackground.childImageSharp.fluid}>
                <Container fluid style={{height: "800px", color: "#182F55"}}>
                    <Row style={{paddingTop: "100px", alignItems: "center"}}>
                        <Col xs={6}>
                            <Image fluid={data.helm.childImageSharp.fluid}/>
                        </Col>
                        <Col xs={5} className="ml-5 mb-5">
                            <Fade right>
                                <h2 className="head-text mb-5">ALDERS VASTGOED&shy;ONDERHOUD IS DOOR HET HELE LAND ACTIEF, 24/7</h2>
                                <p className="normal-text">Het totaalonderhoud van uw woning is voor ons het belangrijkste. Wij streven naar een goede
                                    samenwerking, waarbij u van begin tot eind kunt bouwen op ons vakmanschap. Wij voeren ons werk zorgvuldig uit, om
                                    de kwaliteit te bewaken.<br></br><br></br>
                                    Alders Vastgoedonderhoud is door heel Nederland actief. Daarnaast staan onze vakspecialisten 24/7 voor u klaar.
                                    Bij noodgevallen kunt u direct met ons contact opnemen, zodat het probleem zo snel mogelijk wordt opgelost.</p>
                                <a href="tel:+31-629543404" rel="nofollow" className={"head-text no-underline mt-5 py-2 btn spoedService"} style={{
                                    minWidth: "300px",
                                    backgroundColor: "#182F55",
                                    borderRadius: 0,
                                    display: "inline-block",
                                    border: 0,
                                    color: "white"
                                }}><Image style={{position: "absolute", zIndex: 1, bottom: "-12px", left: "-10%"}}
                                          fixed={data.clock.childImageSharp.fixed}/><span className="showText">SPOEDSERVICE</span><span
                                    className="hiddenText">06 29 54 34 04</span></a>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </BackgroundImage>
        )
    }

    return (
        <>
            <SEO title="Uw Specialist"/>
            <Layout
                width={width}
                backgroundImage={data.backgroundImage.childImageSharp.fluid}
                backgroundImageStyles={{backgroundColor: "black", backgroundPosition: "center", height: "100vh"}}
                coverText={coverText}>
                <Container className="my-5 normal-text">
                    <Row>
                        <Col lg={{span: 5, order: 0}} xs={{span: 12, order: 1}}>
                            <Fade left>
                                <Carousel fade pause={false} nextIcon="" prevIcon="">
                                    {data.backgrounds.nodes.map(image => {
                                        return <Carousel.Item>
                                            <Image fluid={image.childImageSharp.fluid} loading="eager"/>
                                        </Carousel.Item>
                                    })}
                                </Carousel>
                            </Fade>
                        </Col>
                        <Col className="mt-5" xl={{span: 6, offset: 1}} lg={{span: 6}} xs={{span: 12, order: 0}}>
                            <Fade right>
                                <h3 className="head-text mb-3" style={{color: "#1F2D53"}}>WONINGONDERHOUD, VERBETERING EN RENOVATIE</h3>
                                <p>Alders Vastgoedonderhoud is voor verschillende opdrachten in te zetten.
                                    Wij zijn specialist in technische en bouwkundige aspecten van het onderhoud van woningen. Een scherpe prijs,
                                    innovatie en expertise staat bij ons voorop.</p>
                                <p>
                                    Wij bieden vakmanschap op het gebied van:
                                    <ul className="colored-bullets">
                                        <li><span>Dakwerkzaamheden</span></li>
                                        <li><span>Schilderwerk</span></li>
                                        <li><span>Stucwerk</span></li>
                                        <li><span>Timmerwerkzaamheden.</span></li>
                                    </ul>
                                </p>
                                <h5 className="head-text mb-n1" style={{color: "#1F2D53"}}>WONINGONDERHOUD</h5>
                                <p>Wij repareren of vervangen bestaande voorzieningen in uw woning.</p>
                                <h5 className="head-text mb-n1" style={{color: "#1F2D53"}}>VERBETERING EN RENOVATIE</h5>
                                <p>Wij passen voorzieningen toe in uw woning die leiden tot meer woongenot.<br></br><br></br>
                                    Via ons contactformulier kunt u gratis en vrijblijvend, advies op maat aanvragen.</p>
                                <Link to="/contact/" rel="nofollow" className={"head-text no-underline mt-3 btn"} style={{
                                    backgroundColor: "#182F55",
                                    borderRadius: 0,
                                    display: "inline-block",
                                    marginTop: "-6px",
                                    border: 0,
                                    color: "white"
                                }}>ONTVANG ADVIES OP MAAT</Link>
                            </Fade>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="head-text" style={{color: "#1F2D53", textAlign: "center", marginTop: "10%"}}>
                            <h2>WAAROM KIEZEN VOOR ALDERS VASTGOED&shy;ONDERHOUD?</h2>
                            <br></br>
                            <h6>Wij vinden het belangrijk dat u als klant tevreden bent.<br></br>
                                Daarom garandeert Alders Vastgoedonderhoud 100% klanttevredenheid op z’n werkzaamheden.</h6>
                        </Col>
                    </Row>
                    <Row className="head-text my-5" style={{color: "#1F2D53", textAlign: "center"}}>
                        <Col xs={12} xl={{span: 4}} style={{maxWidth: "400px", margin: "auto"}}>
                            <Fade left>
                                <Image style={{transform: "scale(0.5)"}} fixed={data.iconDiamond.childImageSharp.fixed}/>
                                <h5>Top Resultaat</h5>
                                <p className="normal-text">Door onze constructiemethoden en materiaalkeuzes brengen wij ieder project tot een geslaagd
                                    resultaat.</p>
                            </Fade>
                        </Col>
                        <Col xs={12} xl={{span: 4}} style={{maxWidth: "400px", margin: "auto"}}>
                            <Fade bottom>
                                <Image fixed={data.iconPlan.childImageSharp.fixed}/>
                                <h5>Helder stappenplan</h5>
                                <p className="normal-text">Wij werken met een overzichtelijk stappenplan, zodat u precies weet wat u van ons kunt
                                    verwachten.</p>
                            </Fade>
                        </Col>
                        <Col xs={12} xl={{span: 4}} style={{maxWidth: "400px", margin: "auto"}}>
                            <Fade right>
                                <Image style={{transform: "scale(0.7)", marginTop: "-5%"}} fixed={data.iconPaper.childImageSharp.fixed}/>
                                <h5>Professioneel</h5>
                                <p className="normal-text">Door de ruime ervaring kunnen wij hoogwaardig resultaat leveren.</p>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                {helmSection}
                <Container fluid style={{backgroundColor: "#C1B299", paddingBottom: "5%"}}>
                    <Row>
                        <Col className="head-text mt-5" style={{color: "#1F2D53", textAlign: "center"}}>
                            <h2>WAT ZEGGEN ONZE KLANTEN?</h2>
                            <h5>Bekijk de recensies hieronder om te lezen wat andere klanten van ons werk vinden.</h5>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col xs={12} xl={{span: 6, offset: 3}}>
                            <Fade>
                                <Carousel fade className="reviewCarousel" pause={false} nextIcon="" prevIcon="" style={reviewCarouselStyling}>
                                    {data.allGoogleReview.edges.map((edge) => {
                                        const review = edge.node;
                                        if (review.review.length > 300 && width < 900) {
                                            review.review = review.review.substring(0, 311) + "..."
                                        }
                                        return <Carousel.Item>
                                            <img style={{transform: "scale(0.75)"}} alt="Reviewer profile" src={review.image}/><br/>
                                            <a className="no-underline" style={{textDecoration: "none", color: "#1F2D53"}} href={review.url} rel="noreferrer" target="_blank">
                                                <h4><q className="normal-text">{review.review}</q></h4>
                                                <br/><h5>- {review.name}</h5>
                                            </a>
                                        </Carousel.Item>
                                    })}
                                </Carousel>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                <BackgroundImage className="parallax" fluid={data.tools.childImageSharp.fluid}>
                    <Container fluid className="head-text" style={{height: "80vh", textAlign: "center", color: "white"}}>
                        <Row style={{paddingTop: "15vh", alignItems: "center"}}>
                            <Col xs={12}>
                                <h1>NEEM CONTACT MET ONS OP</h1>
                                <h4>Wij zijn snel en makkelijk te bereiken.</h4>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "10vw"}}>
                            <Col md={{span: 6}} xl={{span: 3, offset: 3}}>
                                <Button href="tel:+31-629543404" rel="nofollow" className={"head-text no-underline mt-3"} style={{
                                    minWidth: "300px",
                                    backgroundColor: "#182F55",
                                    borderRadius: 0,
                                    display: "inline-block",
                                    marginTop: "-6px",
                                    border: 0
                                }}>BEL ONS DIRECT</Button>
                            </Col>
                            <Col md={{span: 6}} xl={{span: 3}}>
                                <Link to="/quotation/" rel="nofollow" className={"head-text no-underline mt-3 btn"} style={{
                                    minWidth: "300px",
                                    backgroundColor: "#182F55",
                                    borderRadius: 0,
                                    display: "inline-block",
                                    marginTop: "-6px",
                                    border: 0,
                                    color: "white"
                                }}>OFFERTE AANVRAGEN</Link>
                            </Col>
                        </Row>
                    </Container>
                </BackgroundImage>
            </Layout>
        </>
    )
}

export default Index
